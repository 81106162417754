<script setup lang="ts">
import { computed } from 'vue';
import { PeriodicContractBaggage } from '@/models/periodic-contract';
import { colorToName } from '@/util';

const props = defineProps<{
    baggage: PeriodicContractBaggage;
}>();

const labelText = computed(() => {
    return props.baggage.labelText ?? '';
});

const labelColor = computed(() => {
    return props.baggage.labelColor ?? '';
});
</script>

<template>
    <span v-if="labelText">
        <a-tooltip :title="labelText" placement="top">
            <a-tag :color="colorToName(labelColor)" style="max-width: 120px">
                <div class="label-text">{{ labelText }}</div>
            </a-tag>
        </a-tooltip>
    </span>
</template>

<style scoped lang="less">
.label-text {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
</style>
