export const colorToName = (color: string): string => {
    return {
        '#eb2f96': 'pink',
        '#f5222d': 'red',
        '#fa8c16': 'orange',
        '#52c41a': 'green',
        '#13c2c2': 'cyan',
        '#1890ff': 'blue',
        '#722ed1': 'purple'
    }[color] ?? '';
};
