import { PeriodicContract } from '@/models/periodic-contract';

export const columns = [
    {
        title: '荷主',
        key: 'shipperName',
        scopedSlots: { customRender: 'shipperName' },
        width: 180,
    },
    {
        title: '運賃',
        key: 'freight',
        scopedSlots: { customRender: 'freight' },
        width: 150,
    },
    {
        title: '発',
        key: 'departureLocation',
        scopedSlots: { customRender: 'departureLocation' },
        width: 150,
    },
    {
        title: '着',
        key: 'arrivalLocation',
        scopedSlots: { customRender: 'arrivalLocation' },
        width: 150,
    },
    {
        title: '荷種',
        key: 'baggageType',
        scopedSlots: { customRender: 'baggageType' },
        width: 150,
    },
    {
        title: '車種/重量',
        key: 'truckType',
        scopedSlots: { customRender: 'truckType' },
        width: 150,
    },
    {
        title: '曜日',
        key: 'dayOfWeek',
        scopedSlots: { customRender: 'dayOfWeek' },
        width: 150,
    },
    {
        title: '社内メモ',
        key: 'description',
        scopedSlots: { customRender: 'description' },
        width: 200,
    },
    {
        title: '最終荷物登録実施期間',
        key: 'lastExecutedTerm',
        scopedSlots: { customRender: 'lastExecutedTerm' },
        width: 150,
    },
];

export const periodicContractRawClassName = (record: PeriodicContract, selectedPeriodicContractId: number | undefined): string => {
    const styleClasses = ['app-table-row', 'app-table-row--has-action-column'];
    if (record.id === selectedPeriodicContractId) {
        styleClasses.push('app-table-row--selected');
    }
    return styleClasses.join(' ');
};
