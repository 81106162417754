<script setup lang="ts">
import { ArrivalDataTypeEnum } from '@/models/vo/periodic-contract';
import { computed } from 'vue';

interface Props {
    value?: ArrivalDataTypeEnum,
}
const props = defineProps<Props>();
const emits = defineEmits<{
    // FIXME vue3化する際にupdate:valueイベントに変える
    (e: 'input', value?: ArrivalDataTypeEnum): void,
    (e: 'changeType'): void
}>();
const options = ArrivalDataTypeEnum.values.map(v => ({ label: v.label, value: v.code }));
const selected = computed<number | undefined>({
    get: () => props.value ? props.value.code : undefined,
    set: (value: number | undefined): void => {
        emits('input', ArrivalDataTypeEnum.valueOf(value) ?? undefined );
    }
});
</script>

<template>
    <a-select v-model:value="selected"
              :options="options"
              placeholder="選択なし"
    ></a-select>
</template>

<style scoped lang="less">
::v-deep(.ant-select-selection-search input) {
    caret-color: transparent;
}
</style>
