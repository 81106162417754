import { computed, ref } from 'vue';
import _ from 'lodash';
import { useLoading } from '@/composables/helper/loading-helper';
import {
    PeriodicContract,
    PeriodicContractExecuteFormModel,
    PeriodicContractList,
    PeriodicContractModel,
    PeriodicContractFormModel,
    PeriodicContractSearchFormModel,
} from '@/models/periodic-contract';
import { periodicContractApi } from '@/repository/api/internal/periodic-contract';
import { useNotification } from '@/composables/helper/page-helper';
import { FormValidateUtil } from '@/models/validate-helper';
import { PeriodicContractBaggageTimeTypeEnum } from '@/models/vo/periodic-contract';

export const usePeriodicContractSearch = () => {
    const { state: { loading }, withLoading } = useLoading();
    const list = ref<PeriodicContractList | undefined>();
    const form = ref<PeriodicContractSearchFormModel>(new PeriodicContractSearchFormModel());

    const search = (pageNo: number, pageSize: number) => withLoading(async () => {
        form.value.pageNo = pageNo;
        form.value.pageSize = pageSize;
        list.value = await periodicContractApi.search(form.value);
    });

    return {
        state: {
            loading,
            list,
            form,
        },
        search,
    };
};

export const usePeriodicContractLoad = () => {
    const { state: { loading }, withLoading } = useLoading();
    const periodicContract = ref<PeriodicContractModel | undefined>();

    const load = (periodicContractId: number) => withLoading(async () => {
        periodicContract.value = new PeriodicContractModel(await periodicContractApi.find(periodicContractId));
    });

    const clear = () => {
        periodicContract.value = undefined;
    };

    return {
        state: {
            loading,
            periodicContract,
        },
        load,
        clear,
    };
};

export const usePeriodicContractRegister = () => {
    const notification = useNotification();

    const NOTIFICATION_KEY_FAILED_TO_REGISTER = 'REGISTER_PERIODIC_CONTRACT_FAILED';
    /**
     * 定期契約登録失敗を通知します。
     */
    const notifyFailedToRegister = (): void => notification.error({
        key: NOTIFICATION_KEY_FAILED_TO_REGISTER,
        message: '定期契約を登録できませんでした。',
        description: '時間をおいて再度お試しください。状況が改善しない場合はお問い合わせください。',
        duration: 10,
    });

    /**
     * 定期契約登録失敗通知を閉じます。
     */
    const closeFailedToRegisterNotification = (): void =>
        notification.close(NOTIFICATION_KEY_FAILED_TO_REGISTER);

    const form = ref<PeriodicContractFormModel>(new PeriodicContractFormModel());
    const sourceForm = ref<PeriodicContractFormModel>(new PeriodicContractFormModel());
    const formValidateRules = computed(() => FormValidateUtil.toAntValidator(form.value));
    const dirty = computed<boolean>(() => {
        return !_.isEqual(form.value.toForm(), sourceForm.value.toForm());
    });

    const initForm = (periodicContract: PeriodicContract) => {
        form.value = PeriodicContractFormModel.of(periodicContract);
    };

    const clearDepartureTime = (value: PeriodicContractBaggageTimeTypeEnum) => {
        switch (value.code) {
            case PeriodicContractBaggageTimeTypeEnum.AM.code:
            case PeriodicContractBaggageTimeTypeEnum.PM.code:
            case PeriodicContractBaggageTimeTypeEnum.NO_DESIGNATION.code:
                form.value.departureTime = undefined;
                return;
            default:
                return;
        }
    };

    const clearArrivalTime = (value: PeriodicContractBaggageTimeTypeEnum) => {
        switch (value.code) {
            case PeriodicContractBaggageTimeTypeEnum.AM.code:
            case PeriodicContractBaggageTimeTypeEnum.PM.code:
            case PeriodicContractBaggageTimeTypeEnum.NO_DESIGNATION.code:
                form.value.arrivalTime = undefined;
                return;
            default:
                return;
        }
    };

    const register = async (): Promise<number> => {
        closeFailedToRegisterNotification();
        try {
            const periodicContractId = await periodicContractApi.register(form.value.toApiForm());
            sourceForm.value = _.cloneDeep(form.value);
            return periodicContractId;
        } catch (e) {
            notifyFailedToRegister();
            throw e;
        }
    };

    return {
        state: {
            form,
            formValidateRules,
            dirty,
        },
        initForm,
        clearDepartureTime,
        clearArrivalTime,
        register,
    };
};

export const usePeriodicContractUpdate = () => {
    const notification = useNotification();

    const NOTIFICATION_KEY_FAILED_TO_UPDATE = 'UPDATE_PERIODIC_CONTRACT_FAILED';
    /**
     * 定期契約登録失敗を通知します。
     */
    const notifyFailedToUpdate = (): void => notification.error({
        key: NOTIFICATION_KEY_FAILED_TO_UPDATE,
        message: '定期契約を更新できませんでした。',
        description: '時間をおいて再度お試しください。状況が改善しない場合はお問い合わせください。',
        duration: 10,
    });

    /**
     * 定期契約更新失敗通知を閉じます。
     */
    const closeFailedToUpdateNotification = (): void =>
        notification.close(NOTIFICATION_KEY_FAILED_TO_UPDATE);

    const form = ref<PeriodicContractFormModel>(new PeriodicContractFormModel());
    const sourceForm = ref<PeriodicContractFormModel>(new PeriodicContractFormModel());
    const formValidateRules = computed(() => FormValidateUtil.toAntValidator(form.value));
    const dirty = computed<boolean>(() => {
        return !_.isEqual(form.value.toForm(), sourceForm.value.toForm());
    });

    const initForm = (periodicContract: PeriodicContract) => {
        form.value = PeriodicContractFormModel.of(periodicContract);
        sourceForm.value = _.cloneDeep(form.value);
    };

    const clearDepartureTime = (value: PeriodicContractBaggageTimeTypeEnum) => {
        switch (value.code) {
            case PeriodicContractBaggageTimeTypeEnum.AM.code:
            case PeriodicContractBaggageTimeTypeEnum.PM.code:
            case PeriodicContractBaggageTimeTypeEnum.NO_DESIGNATION.code:
                form.value.departureTime = undefined;
                return;
            default:
                return;
        }
    };

    const clearArrivalTime = (value: PeriodicContractBaggageTimeTypeEnum) => {
        switch (value.code) {
            case PeriodicContractBaggageTimeTypeEnum.AM.code:
            case PeriodicContractBaggageTimeTypeEnum.PM.code:
            case PeriodicContractBaggageTimeTypeEnum.NO_DESIGNATION.code:
                form.value.arrivalTime = undefined;
                return;
            default:
                return;
        }
    };

    const update = async (periodicContractId: number): Promise<void> => {
        closeFailedToUpdateNotification();
        try {
            await periodicContractApi.update(periodicContractId, form.value.toApiForm());
            sourceForm.value = _.cloneDeep(form.value);
        } catch (e) {
            notifyFailedToUpdate();
            throw e;
        }
    };

    return {
        state: {
            form,
            formValidateRules,
            dirty,
        },
        initForm,
        clearDepartureTime,
        clearArrivalTime,
        update,
    };
};


export const usePeriodicContractDestroy = () => {
    const notification = useNotification();
    const notifyFailedKey = 'DELETE_PERIODIC_CONTRACT_FAILED';

    const { state:  { loading }, withLoading } = useLoading();

    const destroy = (id: number) => withLoading(async () => {
        notification.close(notifyFailedKey);
        await periodicContractApi.delete(id).catch(() => {
            notification.error({
                key: notifyFailedKey,
                message: '定期契約情報を削除できませんでした。',
                description: '時間をおいて再度お試しください。'
            });
        });
    });

    return {
        state: {
            loading,
        },
        destroy,
    };
};

export const usePeriodicContractExecute = () => {
    const { state: { loading }, withLoading } = useLoading();
    const form = ref<PeriodicContractExecuteFormModel>(new PeriodicContractExecuteFormModel());
    const formValidateRules = computed(() => FormValidateUtil.toAntValidator(form.value));

    const execute = (id: number) => withLoading(async() => {
        return await periodicContractApi.execute(id, form.value).catch(() => false);
    });

    return {
        state: {
            loading,
            form,
            formValidateRules,
        },
        execute,
    };
};
