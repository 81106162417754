<script setup lang="ts">

import PageLayout from '@/_components/PageLayout.vue';
import PaginationControl from '@/_components/ui/PaginationControl.vue';
import { usePeriodicContractListHelper } from '@/_pages/PeriodicContract/List/periodic-contrace-list-helper';
import PeriodicContractDetailDrawer
    from '@/_pages/PeriodicContract/List/components/Drawer/PeriodicContractDetailDrawer.vue';
import { columns, periodicContractRawClassName } from './table-definition';
import { PeriodicContract } from '@/models/periodic-contract';

interface Props {
    periodicContractId?: number;
}

const props = defineProps<Props>();

const {
    loading,
    pageInfo,
    drawerVisibility,
    onChangePage,
    customRow,
    onClickCloseDetailDrawer,
    onClickGround,
    onClickPeriodicContractRegister,
    onClickRegisterBaggages,
    copyPeriodicContract,
    editPeriodicContract,
    deletePeriodicContract,
    rowSelection,
    periodicContractListData,
    periodicContract,
    isOpenBaggageModal,
    currentPeriodicContractId,
    selectedPeriodicContractIds,
    enableClickBaggageCreate,
    onClickCloseBaggageRegisterModal,
    execForm,
    formModel,
    formValidateRules,
    disabledBaggageRegisterPeriod,
    createBaggage,
} = usePeriodicContractListHelper(props);

const rowClassName = (record: PeriodicContract): string => periodicContractRawClassName(record, currentPeriodicContractId.value);
</script>

<template>
    <page-layout :show-header="false" :back-icon="false">
        <a-layout-content style="height: 100%;" @click="onClickGround">
            <div class="board-container">
                <a-card ref="searchResultRef" class="search-result-container" :body-style="{ padding: 0 }">
                    <a-spin :spinning="loading">
                        <a-card :bodyStyle="{ padding: 0 }">
                            <div class="header-action-area table-nav-pagination">
                                <a-button icon="plus"
                                          :disabled="selectedPeriodicContractIds.length === 0"
                                          @click="onClickRegisterBaggages">
                                    荷物登録
                                </a-button>
                                <a-button type="primary" icon="plus" style="margin-right: 1rem"
                                          @click="onClickPeriodicContractRegister">
                                    契約追加
                                </a-button>
                            </div>
                            <div class="table-nav-pagination">
                                <pagination-control :page-info="pageInfo" @change="onChangePage"/>
                            </div>
                            <a-table class="app-result-list-table"
                                     bordered
                                     :row-selection="rowSelection"
                                     :columns="columns"
                                     :custom-row="customRow"
                                     :data-source="periodicContractListData"
                                     row-key="id"
                                     :pagination="false"
                                     :scroll="{ x: 1500 }"
                                     :row-class-name="rowClassName">
                                <template v-slot:shipperName="text, record">
                                    <div class="table-row-ellipsis-text">{{ record.baggage.shipperName }}</div>
                                </template>
                                <template v-slot:freight="text, record">
                                    <div class="table-row-ellipsis-text">{{ record.freightText }}</div>
                                </template>
                                <template v-slot:departureLocation="text, record">
                                    <a-tooltip placement="bottom"
                                               :overlay-style="{ maxWidth: '240px', whiteSpace: 'pre-wrap' }">
                                        <template v-slot:title>
                                            {{ record.departureFull }}
                                        </template>
                                        <div class="table-row-ellipsis-text">{{ record.departureShort }}</div>
                                    </a-tooltip>
                                </template>
                                <template v-slot:arrivalLocation="text, record">
                                    <a-tooltip placement="bottom"
                                               :overlay-style="{ maxWidth: '240px', whiteSpace: 'pre-wrap' }">
                                        <template v-slot:title>
                                            {{ record.arrivalFull }}
                                        </template>
                                        <div class="table-row-ellipsis-text">{{ record.arrivalShort }}</div>
                                    </a-tooltip>
                                </template>
                                <template v-slot:baggageType="text, record">
                                    <div class="table-row-ellipsis-text">{{ record.baggage.type }}</div>
                                </template>
                                <template v-slot:truckType="text, record">
                                    <div class="table-row-ellipsis-text">{{ record.truckType }}</div>
                                </template>
                                <template v-slot:dayOfWeek="text, record">
                                    <div class="table-row-ellipsis-text">{{ record.dayOfWeek }}</div>
                                </template>
                                <template v-slot:lastExecutedTerm="text, record">
                                    <div class="table-row-ellipsis-text">{{ record.lastExecutedTerm }}</div>
                                </template>
                                <template v-slot:description="text, record">
                                    <a-tooltip placement="bottom"
                                               :overlay-style="{ maxWidth: '240px', whiteSpace: 'pre-wrap' }">
                                        <template v-slot:title>
                                            {{ record.description }}
                                        </template>
                                        <div class="table-row-ellipsis-text">{{ record.description }}</div>
                                    </a-tooltip>
                                </template>
                            </a-table>
                            <div class="table-nav-pagination">
                                <pagination-control :page-info="pageInfo" @change="onChangePage"/>
                            </div>
                        </a-card>
                    </a-spin>
                </a-card>
            </div>
            <periodic-contract-detail-drawer :visible="drawerVisibility"
                                             :periodic-contract="periodicContract"
                                             @editPeriodicContract="editPeriodicContract"
                                             @copyPeriodicContract="copyPeriodicContract"
                                             @deletePeriodicContract="deletePeriodicContract"
                                             @close="onClickCloseDetailDrawer"/>
            <a-modal v-model:visible="isOpenBaggageModal" title="荷物を作成する期間を選択してください。">
                <a-form-model ref="formModel"
                              :model="execForm"
                              :rules="formValidateRules">
                    <a-form-model-item prop="validTerm">
                        <a-range-picker
                            :disabled-date="disabledBaggageRegisterPeriod"
                            format="YYYY/MM/DD"
                            valueFormat="YYYY-MM-DD"
                            v-model:value="execForm.validTerm"
                        />
                    </a-form-model-item>
                </a-form-model>
                <template #footer>
                    <a-button key="back" @click="onClickCloseBaggageRegisterModal">キャンセル</a-button>
                    <a-button
                        key="submit"
                        type="primary"
                        :loading="loading"
                        :disabled="!enableClickBaggageCreate"
                        @click="createBaggage">
                        荷物を作成する
                    </a-button>
                </template>
            </a-modal>
        </a-layout-content>
    </page-layout>
</template>

<style scoped lang="less">
.board-container {
    padding: 24px;
}

.header-action-area {
    display: flex;
    justify-content: space-between;
}

.table-nav-pagination {
    padding: 24px;
    background: @component-background;

    .ant-pagination {
        display: flex;
        justify-content: flex-end;

        > .ant-pagination-options {
            order: 1;
        }

        > :not(.ant-pagination-options) {
            order: 2;
        }
    }
}

.table-row-ellipsis-text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    max-height: 42px;
}

</style>
