import { Route } from 'vue-router/types/router';
import { DetailProps as OpenedMyBaggageDetailProps } from '@/_pages/Baggage/List/tabs/Opened/props';
import { DetailProps as CanceledMyBaggageDetailProps } from '@/_pages/Baggage/List/tabs/Canceled/props';
import { DetailProps as SettlementIncomeDetailProps } from '@/_pages/Baggage/List/tabs/Settlement/tabs/List/Income/props';
import { DetailProps as SettlementOutgoDetailProps } from '@/_pages/Baggage/List/tabs/Settlement/tabs/List/Outgo/props';
import { DetailProps as PeriodicContractDetailProps } from '@/_pages/PeriodicContract/List/props';
import { DateValue } from '@/models/vo/date';
import _ from 'lodash';
import { PreloadProps as PreloadSettlementIncomeListProps } from '@/_pages/Baggage/List/tabs/Settlement/tabs/List/Income/props';
import { PreloadProps as PreloadSettlementOutgoListProps } from '@/_pages/Baggage/List/tabs/Settlement/tabs/List/Outgo/props';

export const useRouteExtension = () => {
    const openedMyBaggageDetailProps = (route: Route): OpenedMyBaggageDetailProps | undefined => {
        return route.query.baggageId !== undefined ? {
            baggageId: Number(route.query.baggageId),
            edit: route.query.edit === 'true',
            registered: route.query.registered === 'true',
            truckCompany: route.query.companyId !== undefined && route.query.truckId !== undefined ?
                {
                    companyId: Number(route.query.companyId),
                    truckId: Number(route.query.truckId)
                } : undefined,
            negotiationCompany: route.query.negotiationCompanyId !== undefined && route.query.negotiationId !== undefined ?
                {
                    companyId: Number(route.query.negotiationCompanyId),
                    negotiationId: Number(route.query.negotiationId)
                } : undefined,
        } : undefined;
    };

    const canceledMyBaggageDetailProps = (route: Route): CanceledMyBaggageDetailProps | undefined => {
        return route.query.baggageId !== undefined ? {
            baggageId: Number(route.query.baggageId)
        } : undefined;
    };

    const settlementIncomeDetailProps = (route: Route): SettlementIncomeDetailProps | undefined => {
        return route.query.settlementId !== undefined ? {
            settlementId: Number(route.query.settlementId),
            edit: route.query.edit === 'true',
        } : undefined;
    };

    const settlementOutgoDetailProps = (route: Route): SettlementOutgoDetailProps | undefined => {
        return route.query.settlementId !== undefined ? {
            settlementId: Number(route.query.settlementId),
        } : undefined;
    };

    const preloadSettlementIncomeListProps = (route: Route): PreloadSettlementIncomeListProps => {
        return {
            payeeDate: !_.isEmpty(route.query.payeeDate) ? new DateValue(route.query.payeeDate as string ?? '') : undefined,
            proxy: Boolean(route.query.proxy ?? null).valueOf(),
        };
    };

    const preloadSettlementOutgoListProps = (route: Route): PreloadSettlementOutgoListProps => {
        return {
            payerDate: !_.isEmpty(route.query.payerDate) ? new DateValue(route.query.payerDate as string ?? '') : undefined,
            proxy: Boolean(route.query.proxy ?? null).valueOf(),
        };
    };

    const periodicContractDetailProps = (route: Route): PeriodicContractDetailProps | undefined => {
        return route.query.periodicContractId !== undefined ? {
            periodicContractId: Number(route.query.periodicContractId)
        } : undefined;
    };

    return {
        openedMyBaggageDetailProps,
        canceledMyBaggageDetailProps,
        preloadSettlementIncomeListProps,
        preloadSettlementOutgoListProps,
        settlementIncomeDetailProps,
        settlementOutgoDetailProps,
        periodicContractDetailProps,
    };
};
