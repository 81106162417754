import {
    PeriodicContract,
    PeriodicContractApiForm,
    PeriodicContractExecuteForm,
    PeriodicContractList,
    PeriodicContractSearchForm,
} from '@/models/periodic-contract';
import { appAxios, isSuccess, JsonResponseBean } from '@/repository/api/internal/base';

export const periodicContractApi = {
    /**
     * 検索します。
     */
    search(param: PeriodicContractSearchForm): Promise<PeriodicContractList> {
        return appAxios.post<JsonResponseBean<PeriodicContractList>>('/api/periodic-contract/search', param).then(isSuccess);
    },
    find(id: number): Promise<PeriodicContract> {
        return appAxios.get<JsonResponseBean<PeriodicContract>>(`/api/periodic-contract/${id}`).then(isSuccess);
    },
    /**
     * 登録します。
     */
    register(param: PeriodicContractApiForm): Promise<number> {
        return appAxios.post<JsonResponseBean<number>>('/api/periodic-contract/register', param).then(isSuccess);
    },
    /**
     * 更新します。
     */
    update(id: number, param: PeriodicContractApiForm): Promise<void> {
        return appAxios.post<JsonResponseBean<void>>(`/api/periodic-contract/${id}/update`, param).then(isSuccess);
    },
    /**
     * 削除します。
     */
    delete(id: number): Promise<void> {
        return appAxios.post<JsonResponseBean<void>>(`/api/periodic-contract/${id}/delete`).then(isSuccess);
    },
    /**
     * 定期契約から荷物登録します。
     */
    execute(id: number, param: PeriodicContractExecuteForm): Promise<boolean> {
        return appAxios.post<JsonResponseBean<boolean>>(`/api/periodic-contract/${id}/execute`, param).then(isSuccess);
    },
};
