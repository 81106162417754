<script setup lang="ts">
import { computed } from 'vue';
import { PeriodicContractDetailTabKey } from './model';
import DrawerContentLayout from '@/_pages/PeriodicContract/List/components/Drawer/common/DrawerContentLayout.vue';
import { PeriodicContractModel } from '@/models/periodic-contract';
import ShipperNameView from '@/_components/parts/baggage/ShipperName.vue';
import StaffView from '@/_components/parts/baggage/Staff.vue';
import TruckView from '@/_components/parts/baggage/Truck.vue';
import DescriptionView from '@/_components/parts/baggage/Descriptions.vue';
import TruckEquipmentView from '@/_components/parts/baggage/TruckEquipment.vue';
import BaggageTypeView from '@/_components/parts/baggage/BaggageType.vue';
import TruckDetailView from '@/_components/parts/baggage/TruckDetail.vue';
import BaggageTemperatureZoneView from '@/_components/parts/baggage/BaggageTemperatureZone.vue';
import LoadingTimeNoteView from '@/_components/parts/baggage/LoadingTimeNote.vue';
import UnloadingTimeNoteView from '@/_components/parts/baggage/UnloadingTimeNote.vue';
import DeliveryTimeView from '@/_components/parts/periodic-contract/DeliveryTime.vue';
import TransportSummary from '@/_components/parts/periodic-contract/TransportSummary.vue';
import LabelView from '@/_components/parts/periodic-contract/Label.vue';
import { useMyBaggageExtension } from '@/composables/my-baggage-extension';

const props = withDefaults(defineProps<{
    visible: boolean;
    tab?: PeriodicContractDetailTabKey;
    periodicContract?: PeriodicContractModel;
}>(), {
    tab: 'periodicContract',
});

const emit = defineEmits<{
    (e: 'close'): void;
    (e: 'changeTab', tab: PeriodicContractDetailTabKey): void;
    (e: 'editPeriodicContract', id: number): void;
    (e: 'copyPeriodicContract', id: number): void;
    (e: 'deletePeriodicContract', id: number): void;
}>();

const {
    negotiationType,
} = useMyBaggageExtension();

const selectedTab = computed<PeriodicContractDetailTabKey>({
    get() {
        return props.tab;
    },
    set(value) {
        emit('changeTab', value);
    }
});

const baggage = computed(() => {
    return props.periodicContract?.baggage;
});
</script>

<template>
    <a-drawer width="600"
              :visible="visible"
              :body-style="{ padding: 0 }"
              :mask="false"
              :destroy-on-close="true"
              @close="emit('close')">
        <a-tabs class="drawer-content-tabs"
                v-model="selectedTab"
                :animated="false"
                :tabBarGutter="0"
                :tabBarStyle="{ padding: '12px 68px 0px 24px', margin: 0 }">
            <a-tab-pane key="periodicContract" tab="定期契約">
                <drawer-content-layout>
                    <div v-if="periodicContract" class="container">
                        <!-- アクション -->
                        <a-row type="flex" justify="end">
                            <a-col>
                                <a-space direction="horizontal" class="drawer-header-actions">
                                    <a-button @click="emit('editPeriodicContract', periodicContract.id)">変更</a-button>
                                    <a-button @click="emit('copyPeriodicContract', periodicContract.id)">コピー
                                    </a-button>
                                    <a-button type="danger"
                                              @click="emit('deletePeriodicContract', periodicContract.id)">
                                        削除
                                    </a-button>
                                </a-space>
                            </a-col>
                        </a-row>
                        <a-descriptions bordered :column="1" size="small">
                            <a-descriptions-item label="契約期間">
                                <span>
                                    {{ periodicContract.validTerm() }}
                                </span>
                            </a-descriptions-item>
                            <a-descriptions-item label="曜日">
                                <span>
                                    {{ periodicContract.dayOfWeek }}
                                </span>
                            </a-descriptions-item>
                            <a-descriptions-item label="社内メモ">
                                <span class="description">
                                    {{ periodicContract.description }}
                                </span>
                            </a-descriptions-item>
                            <a-descriptions-item label="最終荷物登録実施期間">
                                <span>
                                    {{ periodicContract.lastExecutedTerm }}
                                </span>
                            </a-descriptions-item>
                        </a-descriptions>

                        <a-divider>荷物情報</a-divider>
                        <transport-summary :baggage="baggage"/>

                        <a-descriptions bordered :column="1" size="small">
                            <a-descriptions-item label="荷主名">
                                <shipper-name-view :baggage="baggage"/>
                            </a-descriptions-item>
                            <a-descriptions-item label="担当者">
                                <staff-view :baggage="baggage"/>
                            </a-descriptions-item>
                            <a-descriptions-item label="連絡方法">
                                <span>{{ negotiationType(baggage).label }}</span>
                            </a-descriptions-item>
                            <a-descriptions-item label="荷種">
                                <baggage-type-view :baggage="baggage"/>
                            </a-descriptions-item>
                            <a-descriptions-item label="温度帯">
                                <baggage-temperature-zone-view :baggage="baggage"/>
                            </a-descriptions-item>
                            <a-descriptions-item label="希望車種">
                                <truck-view :baggage="baggage"/>
                            </a-descriptions-item>
                            <a-descriptions-item label="車両指定">
                                <truck-detail-view :baggage="baggage"/>
                            </a-descriptions-item>
                            <a-descriptions-item label="必要装備">
                                <truck-equipment-view :baggage="baggage"/>
                            </a-descriptions-item>
                            <a-descriptions-item label="備考">
                                <description-view :baggage="baggage"/>
                            </a-descriptions-item>
                            <a-descriptions-item label="発着日時">
                                <delivery-time-view :baggage="baggage"/>
                            </a-descriptions-item>
                            <a-descriptions-item label="積み時間">
                                <loading-time-note-view :baggage="baggage"/>
                            </a-descriptions-item>
                            <a-descriptions-item label="卸し時間">
                                <unloading-time-note-view :baggage="baggage"/>
                            </a-descriptions-item>
                            <a-descriptions-item label="ラベル">
                                <label-view :baggage="baggage"/>
                            </a-descriptions-item>
                        </a-descriptions>
                    </div>
                </drawer-content-layout>
            </a-tab-pane>
        </a-tabs>
    </a-drawer>
</template>

<style scoped lang="less">
.drawer-content-tabs {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;

    :deep(.ant-tabs-bar) {
        height: @ui-drawer-header-height;
        background-color: @component-background;
    }

    :deep(.ant-tabs-content) {
        height: calc(100vh - @ui-drawer-header-height); /* Fallback */
        height: calc(var(--vh, 1vh) * 100 - @ui-drawer-header-height);
        overflow-x: auto;
    }

    :deep(.ant-tabs-tabpane-active) {
        height: 100%;
    }

    // NOTE 備考に長い文字列を入力したときでもラベルの最小幅を確保するためのスタイル
    :deep(.ant-descriptions-item-label) {
        // ラベルの最小幅を確保
        min-width: 120px !important;
        // 文字の折り返しを防ぐ
        white-space: nowrap !important;
    }
}

.container {
    > * + * {
        margin-top: 16px;
    }
}

.drawer-header-actions {
    position: relative;
    margin-top: -8px;

    button {
        margin-left: 4px;
    }
}
</style>
