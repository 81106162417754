import { computed, onBeforeMount, ref } from 'vue';
import moment from 'moment';
import { Const } from '@/const';
import { usePagination } from '@/composables/global/pagination';
import { ModalUtil, PageUtil } from '@/util';
import { useComponentRef, useMessage } from '@/composables/helper/page-helper';
import {
    usePeriodicContractDestroy,
    usePeriodicContractExecute,
    usePeriodicContractLoad,
    usePeriodicContractSearch
} from '@/composables/periodic-contract';
import { PeriodicContractModel } from '@/models/periodic-contract';
import { useRoute } from '@/composables/helper/route';
import { useRouting } from '@/composables/helper/routing';
import { CustomRow } from '@/types/ant-design';
import { parseInt } from 'lodash';
import { useLoading } from '@/composables/helper/loading-helper';
import { onBeforeRouteUpdate } from 'vue-router/composables';
import { Modal } from 'ant-design-vue';
import { useFormModel } from '@/composables/form-helper';
import { Route } from 'vue-router/types/router';

export const usePeriodicContractListHelper = (
    props: { periodicContractId?: number }
) => {
    const {
        goToPeriodicContractRegister,
        goToPeriodicContractEdit,
        goToPeriodicContractList,
        goToNotFound
    } = useRouting();
    const message = useMessage();
    const { state: { pageSize } } = usePagination();
    const { state: { loading: loadingSearch, form, list }, search } = usePeriodicContractSearch();
    const { state: { periodicContract }, load, clear: clearPeriodicContract } = usePeriodicContractLoad();
    const { state: { loading: loadingDestroy }, destroy } = usePeriodicContractDestroy();
    const { state: {  form: execForm, formValidateRules }, execute } = usePeriodicContractExecute();
    const { withMuteableLoading } = useLoading();
    const { formModel, submit } = useFormModel();
    const pageInfo = computed(() => {
        return {
            totalPageCount: list.value?.totalPageCount ?? 0,
            totalRecordCount: list.value?.totalRecordCount ?? 0,
            currentPage: list.value?.currentPageNumber ?? 1,
            currentPageSize: form.value.pageSize,
            pageSizeOptions: Const.PAGE_SIZE_OPTIONS,
        };
    });

    const periodicContractListData = computed<PeriodicContractModel[]>(() => {
        return list.value?.data.map(each => new PeriodicContractModel(each)) ?? [];
    });
    const { el } = useComponentRef();
    const { currentRoute } = useRoute();
    const { replaceQuery } = useRouting();
    const selectedPeriodicContractIds = ref<number[]>([]);

    const loading = computed<boolean>(() =>
        [loadingSearch, loadingDestroy].some(each => each.value)
    );

    //
    // ページ固有の変数
    //
    /* 連打防止 */
    const routeUpdating = ref<boolean>(false);

    const onClickPeriodicContractRegister = () => goToPeriodicContractRegister();

    /**
     * 検索結果セクションまでウィンドウ内コンテンツをスクロールします。
     */
    const scrollToSearchResults = () => {
        PageUtil.scrollToContentTop(el.value?.offsetTop);
    };

    const onChangePage = async (param: { pageNo: number, pageSize: number }) => {
        await search(param.pageNo, param.pageSize);
        scrollToSearchResults();
        pageSize.value = param.pageSize;
    };

    /**
     * 荷物登録するために選択した定期契約
     */
    const rowSelection = {
        onChange: (selectedRowKeys: string[], selectedRows: PeriodicContractModel[]) => {
            selectedPeriodicContractIds.value = selectedRowKeys.map(id => parseInt(id));
        },
    };

    /**
     * 荷物登録UIの状態制御
     */
    const isOpenBaggageModal = ref<boolean>(false);
    // 荷物を作成するボタンの押下可否
    const enableClickBaggageCreate = ref<boolean>(true);

    /**
     * 荷物が登録できる期間を現在日付より1ヶ月後までとする
     */
    const today = moment().startOf('day');
    const oneMoreThanLater = moment().add(1, 'month').endOf('day');
    const disabledBaggageRegisterPeriod = (current: moment.Moment) => {
        return current && (current < today || current > oneMoreThanLater);
    };

    /**
     * 荷物登録モーダルを表示する
     */
    const onClickRegisterBaggages = async () => {
        isOpenBaggageModal.value = true;
    };

    /**
     * 選択した定期契約について指定の期間分の荷物を作成する
     */
    const createBaggage = () => submit(async() => {
        enableClickBaggageCreate.value = false;
        const results = await Promise.allSettled(
            selectedPeriodicContractIds.value.map(periodicContractId => execute(periodicContractId))
        );
        const errors = results.filter((result: any) => result.value === false);
        if (errors.length > 0) {
            if (selectedPeriodicContractIds.value.length !== errors.length) {
                message.error('一部の荷物登録に失敗しました。');
            } else {
                message.error('荷物登録に失敗しました。');
            }
        } else {
            message.success('荷物を登録しました。');
            // 定期契約一覧上の最終荷物登録実施期間を最新化するためreload
            await search(pageInfo.value.currentPage, pageInfo.value.currentPageSize);
        }

        enableClickBaggageCreate.value = true;
        isOpenBaggageModal.value = false;
    });

    /**
     * 荷物登録モーダルを閉じる
     */
    const onClickCloseBaggageRegisterModal = () => {
        isOpenBaggageModal.value = false;
    };

    /**
     * 現在選択中の定期契約ID
     */
    const currentPeriodicContractId = computed<number | undefined>({
        get: () => {
            const id = currentRoute.query?.periodicContractId;
            return id ? Number(id) : undefined;
        },
        set: async (value) => {
            if (routeUpdating.value || currentPeriodicContractId.value === value) return;
            // URLクエリーの更新をリクエスト。onBeforeMountに失敗したら更新されない。
            await replaceQuery(value ? { periodicContractId: `${ value }` } : {});
        },
    });

    /**
     * ドロワー表示有無
     */
    const drawerVisibility = computed<boolean>(() => {
        return !routeUpdating.value && currentPeriodicContractId.value !== undefined;
    });

    /**
     * 定期契約一覧の選択中明細が変更されると呼び出されます。
     */
    const onSelectedPeriodicContractId = (periodicContractId: number | undefined) => {
        currentPeriodicContractId.value = periodicContractId;
    };

    /**
     * 行クリックで定期契約詳細Drawerを表示する
     */
    const customRow = (record: PeriodicContractModel): CustomRow => {
        return {
            on: {
                click: (event) => {
                    event.stopPropagation();
                    onSelectedPeriodicContractId(record.id);
                },
            },
        };
    };

    /**
     * 荷物詳細ドロワーを閉じる際に呼び出されます。
     */
    const onClickCloseDetailDrawer = () => {
        currentPeriodicContractId.value = undefined;
    };

    /**
     * ページ全体のどこかでクリックされると呼び出されます。
     * - 定期契約テーブルの明細の範囲外をクリックしたときに、定期契約詳細Drawerが開いているとDrawerを閉じます。
     */
    const onClickGround = async () => {
        // 連打によるルーティングの多重実行を防止
        if (routeUpdating.value) return;
        if (drawerVisibility.value) {  // ドロワー表示中 = 定期契約情報がロード済み
            onClickCloseDetailDrawer();
        }
    };

    const loadDetail = async (periodicContractId: number, mute: boolean = false) => {
        await withMuteableLoading(mute, async () => {
            await load(periodicContractId);
        });
    };

    /**
     * コピーボタン
     */
    const onClickCopy = (periodicContractId: number) => {
        goToPeriodicContractRegister(periodicContractId).then(r => null);
    };

    /**
     * 削除ボタン
     */
    const onClickDelete = async (id: number) => {
        Modal.confirm({
            title: '定期契約情報を本当に削除しますか？',
            content: 'この操作は元に戻すことができません。ご注意ください。',
            cancelText: 'キャンセル',
            icon: ModalUtil.createConfirmDeletionIcon,
            okText: '削除',
            okType: 'danger',
            autoFocusButton: 'cancel',
            onOk: async () => {
                await destroy(id).then(() => {
                    message.success('定期契約情報を削除しました。');
                    goToPeriodicContractList();
                });

                await search(form.value.pageNo, form.value.pageSize);
            }
        });
    };

    onBeforeMount(async () => {
        const initialPageSize = pageSize.value ?? Const.DEFAULT_PAGE_SIZE;
        await search(1, initialPageSize);

        if (props?.periodicContractId) {
            try {
                await loadDetail(props.periodicContractId);
            } catch {
                await goToNotFound();
            }
        }
    });

    onBeforeRouteUpdate(async (to, from, next) => {
        // 別ページ遷移リンクの連打による多重実行を抑制
        if (routeUpdating.value) {
            next(false);
            return;
        }

        const parsePeriodicContractId = (route: Route): number | undefined =>
            route.query.periodicContractId ? Number(route.query.periodicContractId) : undefined;
        const toPeriodicContractId = parsePeriodicContractId(to);

        // toがない場合、一覧に戻る
        if (!toPeriodicContractId) {
            clearPeriodicContract();
            next();
            return;
        }

        // 選択明細に変化がある場合
        // - 未選択状態から明細を選択した場合
        // - 異なる明細を選択した場合
        routeUpdating.value = true;
        try {
            await loadDetail(toPeriodicContractId);
            next();
        } catch {
            next(false);
        } finally {
            routeUpdating.value = false;
        }
    });

    return {
        loading,
        pageInfo,
        drawerVisibility,
        customRow,
        onChangePage,
        onSelectedPeriodicContractId,
        onClickCloseDetailDrawer,
        onClickGround,
        onClickRegisterBaggages,
        onClickPeriodicContractRegister,
        editPeriodicContract: (id: number) => goToPeriodicContractEdit(id),
        copyPeriodicContract: (id: number) => onClickCopy(id),
        deletePeriodicContract: (id: number) => onClickDelete(id),
        rowSelection,
        periodicContractListData,
        periodicContract,
        isOpenBaggageModal,
        onClickCloseBaggageRegisterModal,
        currentPeriodicContractId,
        selectedPeriodicContractIds,
        enableClickBaggageCreate,
        execForm,
        formModel,
        formValidateRules,
        disabledBaggageRegisterPeriod,
        createBaggage,
    };
};
