<script setup lang="ts">
import { computed } from 'vue';
import { Select } from 'ant-design-vue';
import {
    PeriodicContractBaggageTimeTypeCode,
    PeriodicContractBaggageTimeTypeEnum
} from '@/models/vo/periodic-contract';

type SelectProps = InstanceType<typeof Select>['$props'];

interface Props {
    value?: PeriodicContractBaggageTimeTypeEnum,
    changeType: Function
}
const props = defineProps<Props>();
const emits = defineEmits<{
    // FIXME vue3化する際にupdate:valueイベントに変える
    (e: 'input', value?: PeriodicContractBaggageTimeTypeEnum): void,
    (e: 'changeType'): void
}>();
const options = PeriodicContractBaggageTimeTypeEnum.values.map(v => ({ label: v.label, value: v.code }));
const selected = computed<string | undefined>({
    get: () => props.value ? props.value.code : undefined,
    set: (value: string | undefined): void => {
        emits('input', PeriodicContractBaggageTimeTypeEnum.valueOf(value) ?? undefined );
    }
});

const handleChange: SelectProps['onChange'] = (value: PeriodicContractBaggageTimeTypeCode) => {
    const timeType = PeriodicContractBaggageTimeTypeEnum.valueOf(value);
    emits('input', timeType);
    props.changeType(timeType);
};
</script>

<template>
    <a-select v-model="selected"
              :options="options"
              placeholder="選択なし"
              @change="handleChange"
    ></a-select>
</template>

<style scoped lang="less">
::v-deep(.ant-select-selection-search input) {
    caret-color: transparent;
}
</style>
