import dayjs from 'dayjs';
import 'dayjs/locale/ja';
import * as holiday_jp from '@holiday-jp/holiday_jp';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isBetween from 'dayjs/plugin/isBetween';
import dayjsCustom from 'dayjs/plugin/customParseFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import { Const } from '@/const';

dayjs.locale('ja');
dayjs.extend(isSameOrBefore);
dayjs.extend(isBetween);
dayjs.extend(dayjsCustom);
dayjs.extend(relativeTime);

export class DateUtil {
    static now(): dayjs.Dayjs {
        return dayjs();
    }

    /**
     * 月末日を返却します。
     */
    static lastDayOfMonth(): dayjs.Dayjs {
        return dayjs().endOf('month');
    }

    /**
     * 来月末日を返却します。
     */
    static lastDayOfNextMonth(): dayjs.Dayjs {
        return dayjs().add(1, 'month').endOf('month');
    }

    static parseDateText(text: string): dayjs.Dayjs {
        return dayjs(`${ text } 00:00:00 +09:00`, 'YYYY-MM-DD HH:mm:ss Z');
    }

    static parseDatetimeText(text: string): dayjs.Dayjs {
        return dayjs(`${ text } +09:00`, 'YYYY-MM-DD HH:mm:ss Z');
    }

    /**
     * 残り日数を取得します。
     * 時刻は考慮せず、日だけを見て比較します。
     */
    static formatDaysLeft(source: string, current?: string): number {
        const now = current ? DateUtil.parseDateText(current) : DateUtil.now().startOf('day');
        return Math.ceil(DateUtil.parseDatetimeText(source).diff(now, 'd', true));
    }

    static formatDateTime(param: dayjs.Dayjs): string {
        return param.format(Const.INTERNAL_DATETIME_FORMAT);
    }

    /**
     * 残り日数・時間を取得します
     */
    static formatDatetimeLeft(date: dayjs.Dayjs | string, base?: dayjs.Dayjs | string): string {
        const target = typeof date === 'string' ? DateUtil.parseDatetimeText(date) : date;
        const baseDate = typeof base === 'string' ? DateUtil.parseDatetimeText(base) : base;
        const diffMinutes = target.diff(baseDate ?? DateUtil.now(), 'minutes');
        if (diffMinutes < 0) return '-';

        const minutesLeft = diffMinutes % 60;
        const diffHours = (diffMinutes - minutesLeft) / 60;
        const hoursLeft = diffHours % 24;
        const daysLeft = (diffHours - hoursLeft) / 24;

        return `${ daysLeft > 0 ? daysLeft.toString() + '日 ' : '' }${ hoursLeft.toString().padStart(2, '0') }:${ minutesLeft.toString().padStart(2, '0') }`;
    }

    static isHoliday(date: dayjs.Dayjs | string): boolean {
        const target = typeof date === 'string' ? DateUtil.parseDateText(date) : date;
        return holiday_jp.isHoliday(target.toDate());
    }

    static isBusinessDay(date: dayjs.Dayjs): boolean {
        // 1:Mon, 2:Tue, 3:Wed, 4:Thu, 5:Fri
        return [1,2,3,4,5].includes(date.day()) && !this.isHoliday(date);
    }

    static isIncluded(date: dayjs.Dayjs, range: [dayjs.Dayjs, dayjs.Dayjs]): boolean {
        return !(date.isBefore(range[0]) || date.isAfter(range[1]));
    }

    /**
     * 途中まで入力した時刻を補完します
     */
    static complementTime = (time: string): string => {
        if (time === undefined) {
            return '';
        }
        const half_sized = time.replace(/[０-９]/g, function (s) {
            return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
        });
        const formatted = half_sized.replace('：', ':');

        const regexp = new RegExp(/^[0-9]{1,2}:[0-9]{1,2}$/);
        if (regexp.test(formatted)) {
            const split = formatted.split(':');
            const hour = new Number(split[0]);
            const min = new Number(split[1]);
            return ('0' + hour).slice(-2) + ':' + ('0' + min).slice(-2);
        }

        const regexpNum = new RegExp(/^[0-9]+$/);
        if (!regexpNum.test(formatted)) {
            return formatted;
        }
        if (new Number(formatted) < 0) return '';

        if (formatted.length == 1 || formatted.length == 2) {
            return ('0' + formatted).slice(-2) + ':00';
        }

        if (formatted.length == 3) {
            return ('0' + formatted.substring(0, 1)).slice(-2) + ':' + ('0' + formatted.substring(1, 3)).slice(-2);
        }
        if (formatted.length == 4) {
            return formatted.substring(0, 2) + ':' + formatted.substring(2, 4);
        }
        return formatted;
    };
}
