import { TbxRouteConfig } from '@/router/routes/types';
import PeriodicContractListPage from '@/_pages/PeriodicContract/List/PeriodicContractList.vue';
import PeriodicContractRegisterPage from '@/_pages/PeriodicContract/Register/PeriodicContractRegister.vue';
import PeriodicContractEditPage from '@/_pages/PeriodicContract/Edit/PeriodicContractEdit.vue';
import { Route } from 'vue-router/types/router';

export const periodicContractRoutes: TbxRouteConfig[] = [
    {
        path: '/periodic-contract/search',
        name: 'PeriodicContractList',
        component: PeriodicContractListPage,
        meta: { title: '定期契約' },
        props: (route: Route) => ({ periodicContractId: Number(route.query.periodicContractId) }),
    },
    {
        path: '/periodic-contract/register',
        name: 'PeriodicContractRegister',
        component: PeriodicContractRegisterPage,
        meta: { title: '定期契約登録' },
        props: (route: Route) => ({
            sourcePeriodicContractId: Number(route.query.sourcePeriodicContractId),
        }),
    },
    {
        path: '/periodic-contract/:periodicContractId/edit',
        name: 'PeriodicContractEdit',
        component: PeriodicContractEditPage,
        meta: { title: '定期契約編集' },
        props: (route: Route) => ({
            periodicContractId: Number(route.params.periodicContractId),
        }),
    },
];
