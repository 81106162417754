import { computed, nextTick, onBeforeMount, ref, VNode } from 'vue';
import _ from 'lodash';
import { useRouting } from '@/composables/helper/routing';
import { usePeriodicContractLoad, usePeriodicContractUpdate } from '@/composables/periodic-contract';
import { useFormModel } from '@/composables/form-helper';
import { useMessage } from '@/composables/helper/page-helper';
import { useLoading } from '@/composables/helper/loading-helper';
import { onBeforeRouteLeave } from 'vue-router/composables';
import { useCompanyPlaceList } from '@/composables/company-place';
import {
    useBaggageHighwayFareOptions,
    useBaggageLargeTruckFlgOptions,
    useBaggageLoadingOptions,
    useBaggageShapeOptions,
    useBaggageTemperatureZoneOptions,
    useBaggageUnloadingOptions,
    useTruckHeightForBaggageOptions,
    useTruckModelRegistrableOptions,
    useTruckWeightRegistrableOptions,
    useTruckWidthForBaggageOptions,
} from '@/composables/option-helper';
import {
    useCompanyStaffNameSuggestionRegister,
    useCompanyStaffNameSuggestionRemove,
    useCompanyStaffNameSuggestions
} from '@/composables/company-staff-name-suggestions';
import { useCompanyMyProfile } from '@/composables/global/company-my-profile';
import { useCircleLabelRegister, useCircleLabelRemove, useCircleLabels } from '@/composables/circle-label';

export const usePeriodicContractEditHelper = (periodicContractId: number) => {
    const { goBack, goToPeriodicContractList } = useRouting();
    const {
        state: { form, formValidateRules, dirty },
        clearArrivalTime,
        clearDepartureTime,
        update,
        initForm
    } = usePeriodicContractUpdate();
    const { state: { loading: loadingPeriodicContract, periodicContract }, load } = usePeriodicContractLoad();
    const { state: { placeList }, load: loadCompanyPlaceList } = useCompanyPlaceList();
    const { state: { suggestionList }, load: loadStaffNameSuggestions } = useCompanyStaffNameSuggestions();
    const { remove: removeStaffName } = useCompanyStaffNameSuggestionRemove();
    const { register: registerStaffName } = useCompanyStaffNameSuggestionRegister();
    const { state: { labelList }, load: loadCircleLabels, clear: clearCircleLabels } = useCircleLabels();
    const { remove: removeCircleLabel } = useCircleLabelRemove();
    const { register: registerCircleLabel } = useCircleLabelRegister();
    const { options: shapeOptions } = useBaggageShapeOptions();
    const { options: loadingOptions } = useBaggageLoadingOptions();
    const { options: unloadingOptions } = useBaggageUnloadingOptions();
    const { options: temperatureZoneOptions } = useBaggageTemperatureZoneOptions();
    const { options: weightOptions } = useTruckWeightRegistrableOptions();
    const { options: modelOptions } = useTruckModelRegistrableOptions();
    const { options: heightOptions } = useTruckHeightForBaggageOptions();
    const { options: widthOptions } = useTruckWidthForBaggageOptions();
    const { options: largeTruckFlgOptions } = useBaggageLargeTruckFlgOptions();
    const { options: highwayFareOptions } = useBaggageHighwayFareOptions();
    const { state: { myProfile }, load: loadMyProfile } = useCompanyMyProfile();
    const circleOptions = computed(() => myProfile.value?.circles?.map((each) => ({
        value: each.id,
        label: each.name,
        key: each.id,
    })));
    const { formModel, submit } = useFormModel();
    const { state: { loading: loadingPeriodicContractUpdate }, withLoading } = useLoading();
    const message = useMessage();

    const loading = computed<boolean>(() =>
        [loadingPeriodicContractUpdate, loadingPeriodicContract].some(each => each.value)
    );
    const specifiedTruckChecked = ref(false);
    const specifiedTruckCheck = computed({
        get: () => specifiedTruckChecked.value || form.value.hasAnySpecifiedTruck,
        set: (value: boolean) => {
            if (!value) form.value.clearSpecifiedTruck();
            specifiedTruckChecked.value = value;
        },
    });
    const specifiedTruckEditable = computed(() => specifiedTruckChecked.value || form.value.hasAnySpecifiedTruck);
    const circleId = computed({
        get: () => form.value.circleId,
        set: async (value: number | undefined) => {
            form.value.circleId = value;
            if (value !== undefined) {
                await loadCircleLabels(value);
            } else {
                clearCircleLabels();
            }
        },
    });

    /**
     * サジェストの項目をフィルターします。
     */
    const staffNameFilterOption = (input: string, option: VNode) => {
        const prop: { title?: string } = option.componentOptions?.propsData ?? {};
        if (!prop) {
            return false;
        }
        return _.isString(prop.title) && prop.title.toUpperCase().indexOf(input.toUpperCase()) >= 0;
    };

    /**
     * サジェスト項目を削除ボタンを押下した際に呼び出されます。
     */
    const onClickStaffNameDelete = async (value: string, event: MouseEvent) => {
        // 後続のオプション選択処理が行われないようする
        event.preventDefault();
        event.stopPropagation();

        // 削除APIを実行
        await removeStaffName(value);
        await loadStaffNameSuggestions();
    };

    /**
     * ラベル項目を削除ボタンを押下した際に呼び出されます。
     */
    const onClickLabelDelete = async (id: number) => {
        if (form.value.circleId !== undefined) {
            await removeCircleLabel(form.value.circleId, id);
            await loadCircleLabels(form.value.circleId);
        }
    };

    // ======================================================
    // Notification
    // ======================================================
    /**
     * 離脱確認モーダルを表示します。
     */
    const confirmInEditing = (): boolean =>
        confirm('画面を移動すると入力中の内容は失われます。よろしいですか？');


    // ======================================================
    // Event
    // ======================================================
    /**
     * フォームの初期化処理
     */
    const initialize = async () => {
        if (periodicContractId) {
            await load(periodicContractId).catch(() => goToPeriodicContractList());
            if (periodicContract.value) {
                initForm(periodicContract.value);
                nextTick(async () => {
                    formModel.value?.validate(() => {
                        // callbackがないとerrorとなるため、empty functionを入れる
                    });
                });
            }
        }

        await Promise.all([
            loadCompanyPlaceList(),
            loadStaffNameSuggestions(),
            loadMyProfile(),
        ]);
    };

    const onClickBack = () => goBack();

    /**
     * 更新ボタンの処理
     */
    const onSubmit = () => submit(async () => withLoading(async () => {
        const onSuccess = async () => {
            message.success('定期契約を更新しました。');
            return goToPeriodicContractList();
        };

        // 担当者登録（失敗してもOKなので例外を潰す＆awaitしない）
        registerStaffName(form.value.staffName).catch(() => {});

        // ラベル登録
        if (form.value.circleId && form.value.labelText && form.value.labelColor)
            registerCircleLabel(form.value.circleId, {
                labelText: form.value.labelText,
                labelColor: form.value.labelColor
            }).catch(() => {});

        await update(periodicContractId).then(onSuccess);
    }));

    // ======================================================
    // LifeCycle
    // ======================================================
    onBeforeMount(() => initialize());

    onBeforeRouteLeave((_to, _from, next) => {
        if (dirty.value && !confirmInEditing()) return next(false);

        return next();
    });

    return {
        loading,
        formModel,
        form,
        formValidateRules,
        placeList,
        shapeOptions,
        loadingOptions,
        unloadingOptions,
        temperatureZoneOptions,
        weightOptions,
        modelOptions,
        heightOptions,
        widthOptions,
        largeTruckFlgOptions,
        highwayFareOptions,
        specifiedTruckCheck,
        specifiedTruckEditable,
        suggestionList,
        circleOptions,
        circleId,
        labelList,
        clearDepartureTime,
        clearArrivalTime,
        staffNameFilterOption,
        onClickStaffNameDelete,
        onClickLabelDelete,
        onClickBack,
        onSubmit,
    };
};
