import { IconType } from '@/_components/ui/types/icon-type';
import { CompanyContractListModel } from '@/models/company-contract-list';
import { CompanyStatusModel } from '@/models/company-status';
import { computed } from 'vue';
import { useRoute } from '@/composables/helper/route';
import _ from 'lodash';
import { useCompanyContracts } from '@/composables/global/company-contracts';
import { useCompanyMyStatus } from '@/composables/global/company-my-status';
import { useEntitlement } from '@/composables/entitlement';
import { EntitlementModel } from '@/models/entitlement';
import { DateTimeValue } from '@/models/vo/datetime';

/**
 * サイドメニュー機能を提供します。
 * メニュー文言・リンク先・カレントパスによる選択状態
 */
export const useSideMenuService = () => {
    const { state: { contracts } } = useCompanyContracts();
    const { state: { companyStatus } } = useCompanyMyStatus();
    const { state: { entitlement } } = useEntitlement();

    const defaultSelectedKeys = ['baggageSearch'];
    const selectedKeys = computed<string[]>(() => {
        const path = currentRoute.path;
        const targetPath = path.replace(/^\/_/, '');
        // パスが長い方を優先
        const eachPaths = definition.value.filter(
            (each) => targetPath.startsWith(each.path)
        );
        if (_.isEmpty(eachPaths)) return [];
        const _selectedKeys = eachPaths.reduce(
            (prv, crt) => prv.path.length > crt.path.length ? prv : crt
        )?.key;
        if (_selectedKeys) return _selectedKeys;
        return defaultSelectedKeys;
    });

    const definition = computed<{ path: string, key: string[] }[]>(() => {
        return sideMenuGroups.value.flatMap(group =>
            group.menus.flatMap(menu =>
                (menu.pathPrefix ?? [menu.path]).map(path => {
                    return { path: path, key: [menu.key] };
                })
            ));
    });

    const { currentRoute } = useRoute();

    const sideMenuGroupsRaw: SideMenuGroup<MenuCheckType>[] = [
        matchingMenuGroup,
    ];

    const sideMenuGroups = computed<SideMenuGroup[]>(() => {
        const context: MenuCheckContext = {
            status: companyStatus.value,
            contracts: contracts.value,
            entitlement: entitlement.value,
            now: DateTimeValue.now(),
        };

        return sideMenuGroupsRaw
            .filter(group => group.show?.(context) ?? true)
            .flatMap(group => {
                return {
                    key: group.key,
                    show: group.show?.(context) ?? true,
                    menus: group.menus
                        .filter(menu => menu.show?.(context) ?? true)
                        .map(menu => {
                            return {
                                ...menu,
                                disable: menu.disable?.(context) ?? false,
                                show: true,
                            };
                        }),
                };
            });
    });

    return {
        state: {
            defaultSelectedKeys,
            selectedKeys,
            sideMenuGroups,
        },
    };
};

const settingMenu: SideMenuItem<MenuCheckType> = {
    title: '設定',
    key: 'setting',
    pathPrefix: ['/setting'],
    path: '/setting',
    icon: 'setting'
};

const disableMatchingMenu = ({ status, entitlement, now }: MenuCheckContext): boolean => (entitlement?.locked(now) || status?.isJudging) ?? false;

const matchingMenuGroup: SideMenuGroup<MenuCheckType> = {
    key: 'matchingGroup',
    show: () => true,
    menus: [
        {
            title: '荷物検索',
            key: 'baggageSearch',
            path: '/baggage/search',
            icon: 'baggage-search',
            disable: disableMatchingMenu
        },
        {
            title: '保存した荷物',
            key: 'favoriteBaggage',
            path: '/baggage/favorites',
            icon: 'baggage-favorite',
            disable: disableMatchingMenu
        },
        {
            title: '最近見た荷物',
            key: 'recentBaggage',
            path: '/baggage/recent',
            icon: 'baggage-recent',
            disable: disableMatchingMenu,
        },
        {
            title: '荷物登録',
            key: 'baggageRegister',
            path: '/baggage/register',
            icon: 'baggage-register',
            disable: disableMatchingMenu,
        },
        {
            title: '荷物一括登録',
            key: 'baggageBulkRegister',
            path: '/baggage/bulk_register',
            icon: 'baggage-register',
            disable: disableMatchingMenu,
        },
        {
            title: '定期契約',
            key: 'periodicContractList',
            path: '/periodic-contract/search',
            icon: 'periodic-contract',
            disable: disableMatchingMenu,
        },
        {
            title: 'マイ荷物・成約',
            key: 'baggageList',
            pathPrefix: ['/baggage/list'],
            path: '/baggage/list/opened',
            icon: 'baggage',
            disable: disableMatchingMenu,
        },
        {
            title: '空車検索・登録',
            key: 'truck',
            pathPrefix: ['/truck'],
            path: '/truck',
            icon: 'truck',
            disable: disableMatchingMenu,
        },
        settingMenu,
    ],
};

type MenuCheckContext = {
    status?: CompanyStatusModel;
    contracts?: CompanyContractListModel;
    pastContracts?: CompanyContractListModel;
    entitlement?: EntitlementModel;
    now: DateTimeValue;
};

type MenuCheckType = (context: MenuCheckContext) => boolean;

export type SideMenuItem<T = boolean> = {
    title: string,
    path: string,
    icon: IconType,
    key: string,
    pathPrefix?: string[],
    show?: T,
    disable?: T,
};

export type SideMenuGroup<T = boolean> = {
    key: string,
    show?: T,
    menus: SideMenuItem<T>[],
};
