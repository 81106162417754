import { RouteConfig } from 'vue-router/types/router';
import { TbxRouteConfig } from '@/router/routes/types';
import { accountRoutes } from '@/router/routes/account';
import { baggageRoutes } from '@/router/routes/baggage';
import { truckRoutes } from '@/router/routes/truck';
import { companyRoutes } from '@/router/routes/company';
import { inquiryRoutes } from '@/router/routes/inquiry';
import { unsubRoutes } from '@/router/routes/unsub';
import { upgradeRoutes } from '@/router/routes/upgrade';
import { settingRoutes } from '@/router/routes/setting';
import { miscRoutes } from '@/router/routes/misc';
import { judgingRoutes } from '@/router/routes/judging';
import { deliveryOrderRoutes } from '@/router/routes/delivery-order';
import { periodicContractRoutes } from '@/router/routes/periodic-contract';
import HomePage from '@/_pages/Home/Home.vue';
import LoginPage from '@/pages/Login/index.vue';
import LogoutPage from '@/pages/Logout/index.vue';
import NotFound from '@/pages/NotFound/index.vue';
import _BlankPage from '@/_pages/Blank.vue';
import _NotFound from '@/_pages/NotFound/NotFound.vue';
import _ from 'lodash';

export const toVueRouteConfig = (routes: TbxRouteConfig[]): RouteConfig[] => {
    const createBackRoutes = (_routes: TbxRouteConfig[], isChild: boolean, acc: TbxRouteConfig[]): TbxRouteConfig[] => {
        const head = _routes.shift();
        if (!head) return acc;
        if (head.children) {
            head.children = createBackRoutes(head.children, true, []);
        }
        if (head.backComponent) {
            head.component = head.backComponent;
            if (isChild) {
                head.path = head.path.replace(/^\//, '');
            }
            head.name = `_/${ head.name }`;
            if (!_.isEmpty(head.path)) {
                acc.push(head);
            }
        }
        return createBackRoutes(_routes, true, acc);
    };
    const backRoutes: RouteConfig[] = createBackRoutes([
        {
            path: '/_',
            name: 'Blank',
            backComponent: _BlankPage,
            children: _.cloneDeep(routes),
        }
    ], false, []);
    const defaultRoutes: RouteConfig[] = routes.map(route => {
        return route;
    });

    return [
        ...backRoutes,
        ...defaultRoutes,
    ];
};

export const tbxRoutes: TbxRouteConfig[] = [
    {
        path: '/',
        name: 'Index',
        component: HomePage,
        meta: { title: 'ホーム' },
    },
    {
        path: '/login',
        name: 'Login',
        component: LoginPage,
        meta: { layout: { name: 'blank' } },
    },
    ...accountRoutes,
    ...baggageRoutes,
    ...truckRoutes,
    ...companyRoutes,
    ...inquiryRoutes,
    ...unsubRoutes,
    ...settingRoutes,
    ...upgradeRoutes,
    ...miscRoutes,
    ...judgingRoutes,
    ...deliveryOrderRoutes,
    ...periodicContractRoutes,
    {
        path: '/logout',
        name: 'Logout',
        component: LogoutPage,
        meta: { layout: { name: 'blank' } },
    },
    {
        // srcの上からroutingしているみたいなので、*は最後に書いておく。
        path: '*',
        name: 'NotFound',
        component: _NotFound,
        backComponent: NotFound,
        meta: { title: 'お探しのページは見つかりません', layout: { name: 'blank' } },
    },
];

export const routes: RouteConfig[] = toVueRouteConfig(tbxRoutes);
