<script setup lang="ts">
import { computed } from 'vue';
import { PeriodicContractBaggage } from '@/models/periodic-contract';
import { ArrivalDataTypeEnum, PeriodicContractBaggageTimeTypeEnum } from '@/models/vo/periodic-contract';
import { Util } from '@/util';

const props = defineProps<{
    baggage: PeriodicContractBaggage;
}>();

const departureTime = (): string => {
    if (!props.baggage) return '';

    if (props.baggage.departureTimeType.code === PeriodicContractBaggageTimeTypeEnum.DESIGNATION.code) {
        return Util.requireNotNull(props.baggage.departureTime);
    }

    const label = PeriodicContractBaggageTimeTypeEnum.valueOf(Util.requireNotNull(props.baggage.departureTimeType.code))?.label;
    return Util.requireNotNull(label);
};
const arrivalDate = (): string => {
    if (!props.baggage) return '';

    const arrivalDate = ArrivalDataTypeEnum.valueOf(props.baggage.arrivalDateType.code);
    let arrivalTime = '';

    if (props.baggage.arrivalTimeType.code === PeriodicContractBaggageTimeTypeEnum.DESIGNATION.code) {
        arrivalTime = Util.requireNotNull(props.baggage.arrivalTime);
    } else {
        arrivalTime = Util.requireNotNull(PeriodicContractBaggageTimeTypeEnum.valueOf(Util.requireNotNull(props.baggage.arrivalTimeType.code))?.label);
    }

    return `${arrivalDate?.label} ${arrivalTime}`;
};

const text = computed<string>(() => {
    return `${ departureTime() } ~ ${ arrivalDate() }`;
});

</script>

<template>
    <span>{{ text }}</span>
</template>

<style scoped lang="less">

</style>
