import { Enum } from '@/types/enum';

/**
 * 時間タイプコード
 */
export type PeriodicContractBaggageTimeTypeCode = 'DESIGNATION' | 'NO_DESIGNATION' | 'AM' | 'PM';

/**
 * 時間タイプ
 */
export class PeriodicContractBaggageTimeTypeEnum implements Enum {
    constructor(public code: PeriodicContractBaggageTimeTypeCode, public label: string) {
    }

    static DESIGNATION = new PeriodicContractBaggageTimeTypeEnum('DESIGNATION', '指定');
    static NO_DESIGNATION = new PeriodicContractBaggageTimeTypeEnum('NO_DESIGNATION', '指定なし');
    static AM = new PeriodicContractBaggageTimeTypeEnum('AM', '午前');
    static PM = new PeriodicContractBaggageTimeTypeEnum('PM', '午後');

    static valueOf = (code?: string): PeriodicContractBaggageTimeTypeEnum | undefined =>
        PeriodicContractBaggageTimeTypeEnum.values.find((value) => value.code === code);

    static values = [
        PeriodicContractBaggageTimeTypeEnum.DESIGNATION,
        PeriodicContractBaggageTimeTypeEnum.NO_DESIGNATION,
        PeriodicContractBaggageTimeTypeEnum.AM,
        PeriodicContractBaggageTimeTypeEnum.PM,
    ];

    static valuesWithoutTimeInput = [
        PeriodicContractBaggageTimeTypeEnum.NO_DESIGNATION,
        PeriodicContractBaggageTimeTypeEnum.AM,
        PeriodicContractBaggageTimeTypeEnum.PM,
    ];
}

/**
 * 着日タイプコード
 */
export type ArrivalDataTypeCode = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7;

/**
 * 着日タイプ
 */
export class ArrivalDataTypeEnum implements Enum<number> {
    constructor(public code: ArrivalDataTypeCode, public label: string) {
    }

    static TODAY = new ArrivalDataTypeEnum(0, '当日');
    static TOMMOROW = new ArrivalDataTypeEnum(1, '翌日');
    static TWO_DAYS_LATER = new ArrivalDataTypeEnum(2, '2日後');
    static THREE_DAYS_LATER = new ArrivalDataTypeEnum(3, '3日後');
    static FOUR_DAYS_LATER = new ArrivalDataTypeEnum(4, '4日後');
    static FIVE_DAYS_LATER = new ArrivalDataTypeEnum(5, '5日後');
    static SIX_DAYS_LATER = new ArrivalDataTypeEnum(6, '6日後');
    static SEVEN_DAYS_LATER = new ArrivalDataTypeEnum(7, '1週間後');

    static valueOf = (code?: number): ArrivalDataTypeEnum | undefined =>
        ArrivalDataTypeEnum.values.find((value) => value.code === code);

    static values = [
        ArrivalDataTypeEnum.TODAY,
        ArrivalDataTypeEnum.TOMMOROW,
        ArrivalDataTypeEnum.TWO_DAYS_LATER,
        ArrivalDataTypeEnum.THREE_DAYS_LATER,
        ArrivalDataTypeEnum.FOUR_DAYS_LATER,
        ArrivalDataTypeEnum.FIVE_DAYS_LATER,
        ArrivalDataTypeEnum.SIX_DAYS_LATER,
        ArrivalDataTypeEnum.SEVEN_DAYS_LATER,
    ];
}
