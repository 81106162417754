<script setup lang="ts">
import { Util } from '@/util';
import { computed } from 'vue';
import TbxIcon from '@/_components/ui/TbxIcon.vue';
import { PeriodicContractBaggage } from '@/models/periodic-contract';
import { ArrivalDataTypeEnum, PeriodicContractBaggageTimeTypeEnum } from '@/models/vo/periodic-contract';

const props = withDefaults(defineProps<{
    baggage?: PeriodicContractBaggage;
}>(), {
});

const departureTime = computed<string>(() => {
    if (!props.baggage) return '';
    return time(props.baggage.departureTimeType, props.baggage.departureTime);
});

const arrivalDay = computed<string>(() => {
    if (!props.baggage) return '';
    return ArrivalDataTypeEnum.valueOf(props.baggage.arrivalDateType.code)?.label ?? '';
});

const arrivalTime = computed<string>(() => {
    if (!props.baggage) return '';
    return time(props.baggage.arrivalTimeType, props.baggage.arrivalTime);
});

const departurePref = computed<string>(() => {
    if (!props.baggage?.departureLocation.prefecture) return '';
    return props.baggage.departureLocation.prefecture.label;
});

const departureCity = computed<string>(() => {
    if (!props.baggage?.departureLocation.city) return '';
    return props.baggage.departureLocation.city;
});

const departureAddress = computed<string>(() => {
    if (!props.baggage?.departureLocation.address) return '';
    return props.baggage.departureLocation.address ?? '';
});

const arrivalPref = computed<string>(() => {
    if (!props.baggage?.arrivalLocation.prefecture) return '';
    return props.baggage.arrivalLocation.prefecture.label;
});

const arrivalCity = computed<string>(() => {
    if (!props.baggage?.arrivalLocation.city) return '';
    return props.baggage.arrivalLocation.city;
});

const arrivalAddress = computed<string>(() => {
    if (!props.baggage?.arrivalLocation.address) return '';
    return props.baggage.arrivalLocation.address ?? '';
});

const freight = computed<string>(() => {
    if (!props.baggage) return '';
    const freight = props.baggage?.freight ?? 0;
    return freight > 0 ? `￥${Util.formatNumber(freight)}` : '要相談';
});

const highwayFare = computed<string>(() => {
    if (!props.baggage) return '';
    return `高速代${props.baggage.highwayFareFlg ? 'あり' : 'なし'}`;
});

const time = (timeType: PeriodicContractBaggageTimeTypeEnum, time?: string): string => {
    if (timeType.code === PeriodicContractBaggageTimeTypeEnum.DESIGNATION.code) {
        return Util.requireNotNull(time);
    }

    const label = PeriodicContractBaggageTimeTypeEnum.valueOf(Util.requireNotNull(timeType.code))?.label;
    return Util.requireNotNull(label);
};
</script>

<template>
    <div class="container">
        <a-row type="flex" align="middle">
            <a-col flex="0 0 47%">
                <div>{{ departureTime }}</div>
                <div class="location">{{ departurePref }} {{ departureCity }} {{ departureAddress }}</div>
            </a-col>
            <a-col flex="0 0 6%" class="caret">
                <tbx-icon class="caret__icon" type="caret-right"/>
            </a-col>
            <a-col flex="0 0 47%">
                <div><span class="date">{{ arrivalDay }}</span> {{ arrivalTime }}</div>
                <div class="location">{{ arrivalPref }} {{ arrivalCity }} {{ arrivalAddress }}</div>
            </a-col>
        </a-row>
        <div>
            <div>
                <span class="freight">{{ freight }}</span>
                <span> {{ highwayFare }}</span>
            </div>
        </div>
    </div>
</template>

<style scoped lang="less">
.container {
    margin-bottom: 16px;
    border-radius: 10px;
    padding: @padding-xs 0;
    overflow: hidden;
    border: 3px solid @color-neutral-5;
    color: @heading-color;

    > * {
        padding: @padding-sm @padding-lg;
    }

    > :first-child {
        border-bottom: 3px solid @color-neutral-5;
    }

    > :nth-child(2) {
        display: flex;
        flex-direction: column;
    }
}

.date {
    font-size: @font-size-lg;
    font-weight: bold;
}

.location {
    margin-top: 4px;
    font-size: @font-size-lg;
    word-break: break-all;
}

.caret__icon {
    color: @color-neutral-5;
    font-size: @heading-3-size;
}

.freight {
    font-size: x-large;
    font-weight: bold;
}

.reference-freight {
    margin-top: 4px;

    &__help {
        margin-left: 4px;
        cursor: pointer;
    }
}
</style>
